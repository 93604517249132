import React, { useState } from "react";
import { saveToDatabase } from "./database";
import { DB_NAME, STORE_NAME, IV_SIZE, SALT_SIZE } from "./config";
import { deriveEncryptionKey } from "./crypto";
import "./App.css";
import PasswordInput from "./Password";
import { Link } from "react-router-dom";
import EncryptHeader from "./EncryptHeader";
import JSZip from "jszip";
import { FaCircleInfo } from "react-icons/fa6";

const FileEncryptor = ({ files, onFileEncrypt }) => {
  const [password, setPassword] = useState("");
  const [passwordStrength, setPasswordStrength] = useState(0); // State to hold password strength
  const [algorithm, setAlgorithm] = useState("AES-CBC");
  const [additionalText, setAdditionalText] = useState("");
  const [weakPassword, setWeakPassword] = useState(false);

  const handleAlgorithmChange = (event) => {
    setAlgorithm(event.target.value);
  };

  const handlePasswordChange = (value, strength) => {
    setPassword(value);
    setPasswordStrength(strength);
  };

  const readFileAsArrayBuffer = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => resolve(event.target.result);
      reader.onerror = (error) => reject(error);
      reader.readAsArrayBuffer(file);
    });
  };

  const encryptFile = async (file) => {
    const data = await readFileAsArrayBuffer(file);
    const salt = window.crypto.getRandomValues(new Uint8Array(SALT_SIZE));
    const iv = window.crypto.getRandomValues(new Uint8Array(IV_SIZE));
    const key = await deriveEncryptionKey(algorithm, password, salt);
    const textEncoder = new TextEncoder();
    let encryptOptions = {};
    if (algorithm === "AES-CBC") {
      encryptOptions = { name: algorithm, iv: iv };
    } else if (algorithm === "AES-GCM") {
      const aad = textEncoder.encode(additionalText); // Convert text to bytes
      const hashedAad = await window.crypto.subtle.digest("SHA-256", aad); // Hash the AAD
      encryptOptions = {
        name: algorithm,
        iv: iv,
        additionalData: hashedAad,
        tagLength: 128,
      }; // tagLength and additionalData are specific to GCM mode
    }
    const encrypted = await window.crypto.subtle.encrypt(
      encryptOptions,
      key,
      data,
    );
    return { file: encrypted, iv: iv, salt: salt };
  };

  const handleFileEncrypt = async () => {
    // console.log("handling encrypt");
    // console.log("password strength", passwordStrength);
    // console.log("files received", files);

    setWeakPassword(!passwordCheck());
    if (!passwordCheck()) {
      return;
    }
    const zip = new JSZip();
    if (files.length >= 1) {
      for (const file of files) {
        zip.file(file.name, file);
      }
    }
    const zipBlob = await zip.generateAsync({ type: "blob" });
    const encrypted = await encryptFile(zipBlob);
    //console.log("encrypted file", encrypted);
    const now = new Date();
    const dateStr = `${now.getDate().toString().padStart(2, "0")}${(
      now.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}${now.getFullYear().toString().slice(-2)}`;
    const timeStr = `${now.getHours().toString().padStart(2, "0")}${now
      .getMinutes()
      .toString()
      .padStart(2, "0")}${now.getSeconds().toString().padStart(2, "0")}`;
    const formattedDateTime = `${dateStr}_${timeStr}`;

    const data = {
      name: files.length > 1 ? `encrypted_${formattedDateTime}` : files[0].name,
      payload: encrypted,
      algorithm: algorithm,
    };
    const result = await saveToDatabase(DB_NAME, STORE_NAME, data);
    // console.log(result);
    onFileEncrypt({ key: result.key, data: data });
  };
  const passwordCheck = () => {
    return passwordStrength > 2 && password.length >= 12;
  };
  return (
    <>
      <EncryptHeader final={false} />
      <PasswordInput
        onPasswordChange={handlePasswordChange}
        action={"encrypt"}
        weakPassword={weakPassword}
      />
      {algorithm === "AES-GCM" && (
        <div className="p-2 w-1/3">
          <div className=" text-2xl sm:text-3xl text-gray-700">
            {" "}
            Additional Text for Authentication:
          </div>
          <textarea
            name="additionalText"
            id="AdditionalText"
            rows="1"
            value={additionalText}
            onChange={(e) => setAdditionalText(e.target.value)}
            className="w-full p-1.5 rounded-xl resize-none"
          ></textarea>
        </div>
      )}
      <Link
        to={
          passwordStrength >= 2 && password.length >= 12 ? "/finalEncrypt" : "#"
        }
        className={`m-2 px-20 py-5  bg-gray-400 text-black rounded-full hover:bg-gray-50 font-bold `}
        onClick={handleFileEncrypt}
      >
        {" "}
        Encrypt{" "}
      </Link>
      <div
        className={`p-0.5 px-8 rounded bg-red-200 ${weakPassword ? "block" : "hidden"}`}
      >
        <div
          className={`flex flex-row items-center justify-center ${passwordStrength < 2 ? "block" : "hidden"}`}
        >
          <FaCircleInfo className="mr-3 text-red-500 font-bold" />
          Password is weak. Please enter a stronger password (ex. Includes
          numbers and/or special characters #@$).
        </div>
        <div
          className={`flex flex-row items-center justify-center ${password.length < 12 ? "block" : "hidden"}`}
        >
          <FaCircleInfo className="mr-3 text-red-500 font-bold" />
          Password is too short. Please enter a password of at least 12
          characters.
        </div>
      </div>
      <div className="text-2xl text-gray-600 m-2 p-2">
        {" "}
        Choose Encryption Algorithm:
      </div>
      <select
        name="Algorithm"
        id="algorithm"
        onChange={handleAlgorithmChange}
        className="p-4 rounded-xl bg-slate-600 text-gray-200"
      >
        <option value="AES-CBC">AES-256 (CBC)</option>
        <option value="AES-GCM">AES-256 (GCM)</option>
      </select>
    </>
  );
};

export default FileEncryptor;
