import { FaHome, FaUnlock } from "react-icons/fa";
import { Link } from "react-router-dom";

const DecryptHeader = ({ final }) => {
  return (
    <>
      <div className="text-3xl sm:text-6xl text-gray-700 sm:m-2 sm:p-2 ">
        <span className="flex flex-col sm:flex-row items-center">
          <FaUnlock className="sm:mr-4" />{" "}
          {final ? `File Decrypted` : `Decrypting your files`}
        </span>
      </div>
      <Link to="/">
        <FaHome className="text-6xl text-gray-700 m-2 p-2" />
      </Link>
    </>
  );
};
export default DecryptHeader;

