import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import zxcvbn from "zxcvbn";

const PasswordInput = ({ onPasswordChange, action, weakPassword }) => {
  const [password, setPassword] = useState("");
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [showPassword, setShowPassword] = useState(false);

  const strengthMap = ["Very Weak", "Weak", "Fair", "Good", "Strong"];

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    const result = zxcvbn(newPassword);
    setPasswordStrength(result.score);
    onPasswordChange(newPassword, result.score);
  };
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="sm:w-3/4 lg:w-1/2">
      <h1 className="text-3xl sm:text-5xl text-gray-700 sm:m-2 sm:p-2 ">
        Enter Your Passphrase
      </h1>
      <div className="text-red-500 sm:text-2xl sm:text-gray-700 m-2 p-2">
        {action === "encrypt"
          ? "This Passphrase will serve as the encryption key. Without it, file restoration will be impossible."
          : "Please use the same passphrase employed during encryption to decrypt the file. Utilizing a different passphrase will render this process ineffective."}
      </div>

      <div
        className={`flex flex-row items-center justify-center rounded-2xl ${weakPassword ? "border-4 border-red-600" : ""}`}
      >
        <input
          type={showPassword ? "text" : "password"}
          id="passwordInput"
          value={password}
          onChange={handlePasswordChange}
          className={`w-full p-1.5  rounded-l-xl  outline-none`}
        />
        <div
          onClick={toggleShowPassword}
          className="text-2xl text-gray-100 bg-slate-500 cursor-pointer p-1.5 rounded-r-xl"
        >
          {showPassword ? <FaEye /> : <FaEyeSlash />}
        </div>
      </div>
      {password.length !== 0 && action === "encrypt" ? (
        <div className="m-2 ">
          <span className="text-gray-600">Password Strength:</span>
          <span className={`text-strength-${passwordStrength}`}>
            {" "}
            {strengthMap[passwordStrength]}
          </span>
          <div className="flex items-center justify-center">
            {[...Array(5)].map((_, i) => (
              <div
                key={i}
                className={`h-2 w-16 sm:w-40 m-1 rounded ${i <= passwordStrength ? `strength-${passwordStrength}` : "bg-gray-300"}`}
              ></div>
            ))}
          </div>
        </div>
      ) : (
        <div className="m-2 " style={{ height: "2.5rem" }}></div>
      )}
    </div>
  );
};

export default PasswordInput;
