const About = () => {
  return (
    <div className='flex flex-col items-center justify-center text-center h-[80vh] w-[90vw] lg:w-3/4 2xl:w-1/2 '>
      <h1 className='text-4xl sm:text-6xl text-green-700'>About WebCryptoDemo </h1>
      <p className='text-xl sm:text-3xl text-gray-800 my-4 text-justify overflow-y-auto sm:overflow-y-hidden'>
        <span className="text-2xl sm:text-4xl text-blue-700 ">WebCryptoDemo</span> allows you to encrypt and decrypt files using AES-CBC  or AES-GCM.
        AES-CBC and AES-GCM are considered the most secure block ciphers as of 2024. If you look
        for extra-security, AES-GCM provides Authenticated Encryption,
        making the encryption even more secure by linking text freely chosen by the user
        to the cipher. You can check 
        <a className="underline text-yellow-700" href="https://www.nist.gov/publications/advanced-encryption-standard-aes-0" target="_blank" rel="noopener noreferrer"> AES encryption specifications by NIST</a>,
        the official agency for technology of the United States of America.
        AES is currently used by banks, secure web protocols and other organisations.

        Unlike most other similar websites, WebCrypto encryption and decryption does not involve communications with a server, all your data remains in the browser and all operations are done inside the secure enclosure of your browser without communication to any website!
        <br />
        <span className="text-2xl text-red-600 ">Your data is safe and never leave your PC.</span>
      </p>
    </div>
  );
}
export default About;