const HowTo = () => {
  return (
    <div className='flex flex-col items-center justify-center text-center h-[80vh] w-11/12 sm:w-3/4 my-2'>
      <h1 className='text-3xl sm:text-6xl text-green-600'>How to use WebCryptoDemo </h1>
      <span className="text-2xl sm:text-4xl text-gray-700  m-2">WebCryptoDemo is very intuitive.</span>
      <div className='text-2xl text-gray-700 my-4 overflow-y-auto'>
        <h2 className='text-3xl text-green-700'>Encrypting files</h2>
        First choose a file that you wish to encrypt using the “Encrypt” button.
        <img src="images/image7.png" width="200px" alt="Placeholder" />
        Then click on “Upload Files”
        <img src="images/image2.png" width="200px" alt="Placeholder" />
        Once the FIle has been loaded, click on “Let’s go” to continue the encryption.
        <img src="images/image4.png" width="200px" alt="Placeholder" />
        Next you will need to choose a passphrase that will generate the encryption key.
        <img src="images/image1.png" width="400px" alt="Placeholder" />
        Be wary that the passphrase must have a minimum complexity otherwise you will not be able to use it.

        Additionally you can choose between AES-CBC or AES-GCM.
        <img src="images/image6.png" width="200px" alt="Placeholder" />
        If you choose AES-GCM, you will also have to enter the additional text to authenticate.

        Once this is done, press (again) the “Encrypt” button.
        <img src="images/image7.png" width="200px" alt="Placeholder" />
        Use the download button to retrieve your encrypted file at the ZIP format.
        <img src="images/image10.png" width="400px" alt="Placeholder" />
        You can immediately encrypt another file using the “Encrypt or Decrypt another file”.
        <img src="images/image8.png" width="400px"  alt="Placeholder" />
        <h2 className='text-4xl text-green-700'>Decrypting files</h2>
        For decryption of a file, use the “Decrypt” button:
        <img src="images/image3.png" width="200px" alt="Placeholder" />
        The process is similar to the encryption, upload the file you wish to decrypt, input the required credentials and download the deciphered file.
        <h2 className='text-4xl text-green-700'> Managing Files</h2>
        All files that you encrypt are stored in your browser and you can manage them using the “List FIles” button.
        <img src="images/image11.png" width="400px"  alt="Placeholder" />
      </div>
    </div>
  );
};
export default HowTo;