import EncryptHeader from "./EncryptHeader";
import DecryptHeader from "./DecryptHeader";
import { Link } from "react-router-dom";
import { saveAs } from "file-saver";

const Final = ({ data, action }) => {
  const downloadEncryptedFile = async () => {
    console.log("payload", data.payload);
    const fileName = data.name;
    const payload = data.payload;
    const encoder = new TextEncoder();
    const algorithm = encoder.encode(data.algorithm);
    console.log("algorithm", algorithm, algorithm.length);
    // Create a Blob from the lengths and the data
    const blob = new Blob([payload.iv, payload.salt, algorithm, payload.file], {
      type: "application/octet-stream",
    });

    // Save the Blob to a file
    saveAs(blob, `${fileName}.cipf`);
  };
  const downloadDecryptedFile = async () => {
    if (data === null) {
      window.alert("Decryption failed. Please check your password.");
      return;
    }
    const fileName = data.name.replace(".cipf", "");
    const payload = data.payload;
    const blob = new Blob([payload], { type: "application/octet-stream" });
    saveAs(blob, `${fileName}.zip`);
  };
  return (
    <>
      {action === "encrypt" ? (
        <EncryptHeader final={true} />
      ) : (
        <DecryptHeader final={true} />
      )}
      <button
        onClick={
          action === "encrypt" ? downloadEncryptedFile : downloadDecryptedFile
        }
        className="px-4 py-2 m-2 bg-gray-700 text-white rounded-full hover:bg-gray-500 w-96"
      >
        {" "}
        Download
      </button>
      <Link
        to="/"
        className="px-4 py-2 bg-gray-700 m-2 text-white rounded-full hover:bg-gray-500 w-96"
      >
        {" "}
        Encrypt or Decrypt another file
      </Link>
      <Link
        to="/listFiles"
        className="px-4 py-2 bg-gray-700 m-2 text-white rounded-full hover:bg-gray-500 w-96"
      >
        {" "}
        List Your Files
      </Link>
    </>
  );
};
export default Final;
