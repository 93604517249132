export const openDatabase = (dbName) => {
  return new Promise((resolve, reject) => {
    const openRequest = indexedDB.open(dbName, 3);

    openRequest.onupgradeneeded = function (e) {
      const db = e.target.result;
      if (!db.objectStoreNames.contains("EncryptedFilesStore")) {
        db.createObjectStore("EncryptedFilesStore", { autoIncrement: true });
      }
    };

    openRequest.onsuccess = function (e) {
      resolve(e.target.result);
    };

    openRequest.onerror = function (e) {
      reject("Error opening database");
    };
  });
};

export const saveToDatabase = (dbName, storeName, encryptedFile) => {
  console.log("saving to db");
  return new Promise((resolve, reject) => {
    const dbRequest = openDatabase(dbName);

    dbRequest
      .then((db) => {
        const transaction = db.transaction(storeName, "readwrite");
        const store = transaction.objectStore(storeName);
        const addRequest = store.add(encryptedFile);

        addRequest.onsuccess = function (e) {
          resolve({ message: "File saved successfully", key: e.target.result });
        };

        addRequest.onerror = function (e) {
          reject("Error saving file");
        };
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteFromDatabase = (dbName, storeName, fileId) => {
  return new Promise((resolve, reject) => {
    const dbRequest = openDatabase(dbName);

    dbRequest
      .then((db) => {
        const transaction = db.transaction(storeName, "readwrite");
        const store = transaction.objectStore(storeName);
        const deleteRequest = store.delete(fileId);

        deleteRequest.onsuccess = function () {
          resolve("File deleted successfully");
        };

        deleteRequest.onerror = function (e) {
          reject("Error deleting file");
        };
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function getFiles(dbName, storeName) {
  return new Promise((resolve, reject) => {
    const dbRequest = openDatabase(dbName);

    dbRequest
      .then((db) => {
        const transaction = db.transaction(storeName, "readonly");
        const store = transaction.objectStore(storeName);

        const dbData = [];

        store.openCursor().onsuccess = function (event) {
          const cursor = event.target.result;
          if (cursor) {
            dbData.push({ key: cursor.key, data: cursor.value });
            cursor.continue();
          } else {
            // No more entries, resolve the Promise
            resolve(dbData);
          }
        };

        transaction.onerror = function (e) {
          reject(`Error reading data ${e}`);
        };
      })
      .catch((error) => {
        reject(`Error opening database ${error}`);
      });
  });
}
