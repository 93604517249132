import { useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import FileDecryptor from "./FileDecryptor";
import FileEncryptor from "./FileEncryptor";
import FileList from "./FileList";
import FileUploader from "./FileUploader";
import Final from "./Final";
import Welcome from "./welcome";
import { Link } from "react-router-dom";
import HowTo from "./HowTo";
import About from "./About";
import { FaHelmetSafety } from "react-icons/fa6";

const InfoPanel = () => {
  return (
    <div>
      <Link to="/" className="text-black font-sans  font-bold">
        <span className="hidden sm:inline">WebCryptoDemo &nbsp; &nbsp;</span>
      </Link>
      <Link to="/about" className="text-black font-sans  font-bold">
        <span className="hidden sm:inline">About &nbsp; &nbsp;</span>
      </Link>
      <Link to="/howto" className="text-black font-sans  font-bold">
        <span className="hidden sm:inline">How To &nbsp; &nbsp;</span>
      </Link>
    </div>
  );
};

function App() {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [encryptedFile, setEncryptedFile] = useState(null);
  const [decryptedFile, setDecryptedFile] = useState(null);

  const onFileUpload = (files) => {
    setUploadedFiles(files);
  };

  const handleFileEncrypt = (encryptedData) => {
    console.log("Encrypted data: ", encryptedData.data);
    setEncryptedFile(encryptedData.data);
  };

  const handleFileDecrypt = (decryptedData, status) => {
    console.log("Decrypted data: ", decryptedData);
    setDecryptedFile(decryptedData);
    console.log("Decryption status: ", status);
  };
  return (
    <Router>
      <div className="App">
        <div className="App-header flex flex-row justify-between items-center">
          <div className="flex flex-row text-left mx-4 gap-1 items-center font-extrabold w-1/3">
            <FaHelmetSafety />
            <Link to="/">WebCryptoDemo</Link>
          </div>
          <InfoPanel />
          <div className="w-1/3"></div>
        </div>
        <div className="flex flex-col items-center justify-center text-center min-h-[80vh]">
          <Routes>
            <Route path="/" element={<Welcome />} />
            <Route
              path="/encrypt"
              element={
                <FileUploader onFileUpload={onFileUpload} action={"encrypt"} />
              }
            />
            <Route
              path="/decrypt"
              element={
                <FileUploader onFileUpload={onFileUpload} action={"decrypt"} />
              }
            />
            <Route
              path="/password"
              element={
                <FileEncryptor
                  files={uploadedFiles}
                  onFileEncrypt={handleFileEncrypt}
                />
              }
            />
            <Route
              path="/passwordDecrypt"
              element={
                <FileDecryptor
                  files={uploadedFiles}
                  onFileDecrypt={handleFileDecrypt}
                />
              }
            />
            <Route
              path="/passwordDecrypt2"
              element={
                <FileDecryptor
                  files={uploadedFiles}
                  onFileDecrypt={handleFileDecrypt}
                  fromDatabase={true}
                />
              }
            />
            <Route
              path="/finalEncrypt"
              element={<Final data={encryptedFile} action={"encrypt"} />}
            />
            <Route
              path="/finalDecrypt"
              element={<Final data={decryptedFile} action={"decrypt"} />}
            />
            <Route
              path="/listFiles"
              element={<FileList onFileUpload={onFileUpload} />}
            />
            <Route path="*" element={<Welcome />} />
            <Route path="/howto" element={<HowTo />} />
            <Route path="/about" element={<About />} />
          </Routes>
        </div>

        <div className="App-header2">
          <div>
            <font size={"small"}>[c] <a href={"https://scd.guru"}>SCD</a></font>
            <div>Developed with ❤️ with ReactJS</div>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
