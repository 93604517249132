import { AES_KEY_LENGTH, PBKDF2_ITERATIONS } from "./config";
export const deriveEncryptionKey = async (algorithm, password, salt) => {
  const encoded = new TextEncoder().encode(password);
  const keyMaterial = await window.crypto.subtle.importKey(
    "raw",
    encoded,
    { name: "PBKDF2" },
    false,
    ["deriveKey", "deriveBits"]
  );
  return window.crypto.subtle.deriveKey(
    { "name": "PBKDF2", salt: salt, iterations: PBKDF2_ITERATIONS, hash: "SHA-256" },
    keyMaterial,
    { "name": algorithm, length: AES_KEY_LENGTH },
    true,
    ["encrypt", "decrypt"]
  );
};