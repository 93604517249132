import { FaHome, FaLock } from "react-icons/fa";
import { Link } from "react-router-dom";

const EncryptHeader = ({ final }) => {
  return (
    <>
      <div className="text-3xl sm:text-6xl text-gray-700 sm:m-2 sm:p-2 flex flex-col items-center">
        <span className="flex flex-col sm:flex-row items-center">
          <FaLock className="sm:mr-4" />{" "}
          {final ? `File Encrypted` : `Encrypting your files`}
        </span>
        {final && (
          <div className="text-2xl sm:text-4xl text-gray-700 m-1">
            Ready for download and viewable in your File List
          </div>
        )}
      </div>
      <Link to="/">
        <FaHome className="text-3xl sm:text-6xl text-gray-700 m-1 sm:m-2 sm:p-2" />
      </Link>
    </>
  );
};
export default EncryptHeader;
