import { Link } from "react-router-dom";
import { FaList, FaHome } from "react-icons/fa";
import { useEffect, useState } from "react";
import { deleteFromDatabase, getFiles } from "./database";
import { DB_NAME, STORE_NAME } from "./config";

function FileList({ onFileUpload }) {
  const [files, setFiles] = useState([]);
  useEffect(() => {
    getFiles(DB_NAME, STORE_NAME)
      .then((files) => {
        setFiles(files);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleDelete = (key) => {
    if (window.confirm("Are you sure you want to delete this file?")) {
      console.log("Deleting file: ", key);
      deleteFromDatabase(DB_NAME, STORE_NAME, key)
        .then((message) => {
          console.log(message);
          const prevFiles = files;
          const newFiles = prevFiles.filter((file) => file.key !== key);
          setFiles(newFiles);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  const handleDecrypt = (file) => {
    console.log("Decrypting file:", file);
    onFileUpload(file);
  };

  return (
    <>
      <h1 className="text-3xl sm:text-6xl text-gray-700 sm:m-2 sm:p-2 ">
        <span className="flex flex-row ">
          <FaList className="mr-4" /> Listing your files
        </span>
      </h1>
      <Link to="/">
        <FaHome className="text-4xl sm:text-6xl text-gray-700 m-1 sm:m-2 sm:p-2" />
      </Link>
      <div className="p-1 h-3/4 md:w-3/5 flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 overflow-y-auto">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow border-b border-gray-200 sm:rounded-lg">
              <div className="bg-gray-500 grid grid-cols-6 text-center text-lg font-extrabold text-gray-100 uppercase tracking-wider">
                <div className="p-1 sm:px-4 sm:py-2 col-span-4">File Name</div>
                <div className="p-1 sm:px-4 sm:py-2 col-span-2">Action</div>
              </div>
              <div className="bg-gray-50 divide-y divide-gray-200 ">
                {files.map((file) => (
                  <div
                    key={file.key}
                    className="p-1 sm:px-3 sm:py-2 whitespace-nowrap grid grid-cols-7"
                  >
                    <div className="p-1 sm:px-3  text-sm sm:text-lg text-gray-500 overflow-ellipsis overflow-hidden col-span-5">
                      {file.data.name}
                    </div>
                    <div className="col-span-2">
                      <div className="text-sm text-gray-500 flex flex-row items-center sm:justify-center justify-around">
                        <Link
                          to="/passwordDecrypt2"
                          className="p-1 sm:px-4 sm:py-2  bg-gray-200 text-black rounded-full hover:bg-gray-50 font-bold"
                          onClick={() => handleDecrypt(file.data)}
                        >
                          Decrypt
                        </Link>
                        <input
                          type="button"
                          value="Delete"
                          className="p-1 sm:px-4 sm:py-2 sm:mx-2 bg-red-500 text-black rounded-full hover:bg-red-700 font-bold"
                          onClick={() => handleDelete(file.key)}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FileList;
