import React from 'react';
import { Link } from 'react-router-dom';

const Welcome = () => {
  return (
    <div className='grid grid-cols-1 sm:grid-cols-2'>
      <div className='flex flex-col items-center p-4'>
      <div className="text-6xl text-gray-700 sm:w-2/3"><span className='font-bold'>Protect</span> your files with a few simple clicks</div>
      <div className="flex flex-wrap lg:space-x-4 mt-8 items-center justify-center">
        <Link to="/encrypt" className="m-2 px-20 py-6 bg-gray-200 text-black rounded-full hover:bg-gray-50 font-bold">Encrypt</Link>
        <Link to="/decrypt" className="m-2 px-20 py-6  bg-gray-200 text-black rounded-full hover:bg-gray-50 font-bold">Decrypt</Link>
      </div>
      <div className="flex mt-2 lg:mt-8">
        <Link to="/listFiles" className="m-2 px-20 py-6 bg-gray-200 text-black rounded-full hover:bg-gray-50 font-bold">List Files</Link>

      </div>
      </div>
      <div className='mx-4 hidden sm:block'>
        <img src='shield.png' width={500} height={500} alt={"shield"}/>
      </div>
    </div>
  );
};

export default Welcome;