import React, { useEffect, useRef, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import DecryptHeader from "./DecryptHeader";
import EncryptHeader from "./EncryptHeader";
import FilesDragAndDrop from "./FilesDragAndDrop";

function formatFileSize(size) {
  if (size < 1024) {
    return `${size} bytes`;
  } else if (size < 1024 * 1024) {
    return `${(size / 1024).toFixed(2)} KB`;
  } else {
    return `${(size / 1024 / 1024).toFixed(2)} MB`;
  }
}

function FileUploader({ onFileUpload, action }) {
  const [files, setFiles] = useState([]);
  const fileInput = useRef(null);

  const handleFileDrag = (files) => {
    console.log("Files from drag and drop: ", files);
    setFiles((prevFiles) => [...prevFiles, ...files]);
  };
  const handleFileChange = (e) => {
    //  console.log("Files from event", e.target.files);
    const curFiles = Array.from(e.target.files);
    setFiles((prevFiles) => [...prevFiles, ...curFiles]);
  };
  const handleButtonClick = () => {
    fileInput.current.click();
  };
  const cancelUpload = (f) => {
    setFiles(files.filter((cur) => cur !== f));
  };
  useEffect(() => {
    //  console.log("Files from state: ", files);
    onFileUpload(files);
  }, [files, onFileUpload]);

  return (
    <>
      {action === "encrypt" ? (
        <EncryptHeader final={false} />
      ) : (
        <DecryptHeader />
      )}
      <div className=" flex flex-col p-6 bg-gray-800 bg-opacity-50 border border-dashed rounded-md w-5/6 sm:w-1/2 justify-around items-center">
        <label className="text-2xl text-gray-100">Edit in Admin</label>
        <label className="text-4xl text-gray-100">Or</label>
        {files &&
          files.length > 0 &&
          files.map((f, index) => (
            <div
              key={index}
              className="text-gray-100 bg-slate-900 p-2 flex flex-col items-center"
            >
              {f.name} ({formatFileSize(f.size)})
              <button
                className="p-1 rounded border border-solid"
                onClick={() => cancelUpload(f)}
              >
                <FaTimes />
              </button>
            </div>
          ))}
        <FilesDragAndDrop onUpload={handleFileDrag} />
        <input
          type="file"
          id="file"
          name="file"
          onChange={handleFileChange}
          ref={fileInput}
          style={{ display: "none" }}
          multiple
        />
        <div>
          <button
            onClick={handleButtonClick}
            className="m-2 px-4 py-2 bg-gray-400 text-white rounded-full hover:bg-gray-700"
          >
            Upload Files
          </button>
        </div>
        <div>
          <Link
            to={action === "encrypt" ? "/password" : "/passwordDecrypt"}
            onClick={(e) => {
              if (files.length === 0) e.preventDefault();
            }}
            className="m-2 px-8 py-2 bg-gray-200 text-black rounded-full hover:bg-gray-50 font-bold"
          >
            {" "}
            Let's GO!
          </Link>
        </div>
      </div>
    </>
  );
}

export default FileUploader;
