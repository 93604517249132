import { useEffect, useRef, useState } from "react";
import { FaCircleInfo } from "react-icons/fa6";

export default function FilesDragAndDrop({ onUpload }) {
  const drop = useRef(null);
  const [dragging, setDragging] = useState(false);
  const [badUpload, setBadUpload] = useState(false);

  useEffect(() => {
    const handleDrop = (e) => {
      setBadUpload(false);
      e.preventDefault();
      e.stopPropagation();
      console.log(e.dataTransfer.files);
      const draggedFiles = [...e.dataTransfer.files];
      const files = draggedFiles.filter((file) => file.size !== 0);
      console.log(files.length, draggedFiles.length);
      if (files.length !== draggedFiles.length) {
        console.log("Bad Upload detected");
        setBadUpload(true);
      }
      if (files && files.length) {
        onUpload(files);
      }
    };
    const handleDragOver = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };
    const handleDragEnter = (e) => {
      e.preventDefault();
      e.stopPropagation();

      setDragging(true);
    };

    const handleDragLeave = (e) => {
      e.preventDefault();
      e.stopPropagation();

      setDragging(false);
    };
    const dropRef = drop.current;
    dropRef.addEventListener("dragover", handleDragOver);
    dropRef.addEventListener("drop", handleDrop);
    dropRef.addEventListener("dragenter", handleDragEnter);
    dropRef.addEventListener("dragleave", handleDragLeave);

    return () => {
      dropRef.removeEventListener("dragover", handleDragOver);
      dropRef.removeEventListener("drop", handleDrop);
      dropRef.removeEventListener("dragenter", handleDragEnter);
      dropRef.removeEventListener("dragleave", handleDragLeave);
    };
  }, []);
  return (
    <>
      <div
        ref={drop}
        className={`flex items-center justify-center w-full h-36 text-2xl text-gray-200 text- border border-dashed bg-gray-800 ${dragging ? "bg-opacity-80" : "bg-opacity-50 "}`}
      >
        {dragging ? (
          "Drop Here"
        ) : (
          <div>
            Drag and drop files here or use the button below to upload files.
            <br />
            <span className="text-red-500">Folders are not supported</span>
          </div>
        )}
      </div>
      {badUpload && (
        <div className="m-1 p-0.5 px-8 rounded bg-red-200">
          <div className={`flex flex-row items-center justify-center `}>
            <FaCircleInfo className="mr-3 text-red-500 font-bold" />
            Incorrect file upload detected. Uploading folders or files of zero
            size is not supported.
          </div>
        </div>
      )}
    </>
  );
}
