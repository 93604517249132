import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DecryptHeader from "./DecryptHeader";
import PasswordInput from "./Password";
import { deriveEncryptionKey } from "./crypto";
import { FaCircleInfo } from "react-icons/fa6";
import { IV_SIZE, SALT_SIZE, ALGORITHM_SIZE } from "./config";

const FileDecryptor = ({ files, onFileDecrypt, fromDatabase }) => {
  const [password, setPassword] = useState("");
  const [algorithm, setAlgorithm] = useState("AES-CBC");
  const [additionalText, setAdditionalText] = useState("");
  const [incorrectPassword, setIncorrectPassword] = useState(false);
  const navigate = useNavigate();

  const handlePasswordChange = (value) => {
    setPassword(value);
  };
  const handleAlgorithmChange = (event) => {
    setAlgorithm(event.target.value);
  };

  const decryptFile = async (encrypted, salt, iv, algorithm) => {
    const key = await deriveEncryptionKey(algorithm, password, salt);
    const textEncoder = new TextEncoder();
    if (algorithm === "AES-CBC") {
      const decrypted = await window.crypto.subtle.decrypt(
        { name: algorithm, iv: iv },
        key,
        encrypted,
      );
      return decrypted;
    } else if (algorithm === "AES-GCM") {
      const aad = textEncoder.encode(additionalText); // Convert text to bytes
      const hashedAad = await window.crypto.subtle.digest("SHA-256", aad); // Hash the AAD
      const decrypted = await window.crypto.subtle.decrypt(
        { name: algorithm, iv: iv, additionalData: hashedAad, tagLength: 128 },
        key,
        encrypted,
      );
      return decrypted;
    }
  };

  async function deserializeBlob(blob) {
    const arrayBuffer = await blob.arrayBuffer();

    const iv = new Uint8Array(arrayBuffer, 0, IV_SIZE);
    const salt = new Uint8Array(arrayBuffer, IV_SIZE, SALT_SIZE);

    const algorithmBytes = new Uint8Array(
      arrayBuffer,
      IV_SIZE + SALT_SIZE,
      ALGORITHM_SIZE,
    );
    const algorithm = new TextDecoder().decode(algorithmBytes);

    const fileContentStart = IV_SIZE + SALT_SIZE + ALGORITHM_SIZE;
    const file = new Uint8Array(arrayBuffer, fileContentStart);

    return {
      iv,
      salt,
      algorithm,
      file, // This is still in bytes; convert or handle as needed
    };
  }

  const handleFileDecrypt = async () => {
    setIncorrectPassword(false);
    try {
      console.log(files);
      const fileName = fromDatabase ? files.name : files[0].name;
      const { iv, salt, file } = fromDatabase
        ? files.payload
        : await deserializeBlob(files[0]);

      const decryptedFile = await decryptFile(file, salt, iv, algorithm);
      const data = {
        name: fileName,
        payload: decryptedFile,
      };
      onFileDecrypt(data, "success");
      navigate("/finalDecrypt");
    } catch (error) {
      console.error("Error decrypting and downloading file:", error);
      setIncorrectPassword(true);
    }
  };
  return (
    <>
      <DecryptHeader />
      <PasswordInput
        onPasswordChange={handlePasswordChange}
        action="decypt"
        weakPassword={incorrectPassword}
      />
      {algorithm === "AES-GCM" && (
        <div className="p-2 w-1/3">
          <div className=" text-2xl sm:text-3xl text-gray-700">
            {" "}
            Additional Text for Authentication:
          </div>
          <textarea
            name="additionalText"
            id="AdditionalText"
            rows="1"
            value={additionalText}
            onChange={(e) => setAdditionalText(e.target.value)}
            className="w-full p-1.5 rounded-xl resize-none"
          ></textarea>
        </div>
      )}
      <button
        className="px-20 py-5 bg-gray-400 text-black rounded-full hover:bg-gray-50 font-bold"
        onClick={handleFileDecrypt}
      >
        Decrypt
      </button>
      {incorrectPassword && (
        <div className="m-1 p-0.5 px-8 rounded bg-red-200">
          <div className={`flex flex-row items-center justify-center `}>
            <FaCircleInfo className="mr-3 text-red-500 font-bold" />
            Error decrypting file. Please check your password
          </div>
        </div>
      )}
      <div className="text-2xl text-gray-600 m-2 p-2">
        {" "}
        Choose Decryption Algorithm:
      </div>
      <select
        name="Algorithm"
        id="algorithm"
        onChange={handleAlgorithmChange}
        className="p-4 rounded-xl bg-slate-600 text-gray-200"
      >
        <option value="AES-CBC">AES-256 (CBC)</option>
        <option value="AES-GCM">AES-256 (GCM)</option>
      </select>
    </>
  );
};

export default FileDecryptor;
